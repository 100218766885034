import useMediaQuery from '@mui/material/useMediaQuery'

// To perform the server-side hydration, the hook needs to render twice.
// A first time with false, the value of the server, and a second time with the resolved value.
// This double pass rendering cycle comes with a drawback. It's slower.
// You can set the noSsr option to true if you are doing client-side only rendering.
// Link: https://mui.com/material-ui/react-use-media-query/#client-side-only-rendering

const noSsr = false
const useResponsive = () => {
  const xs = useMediaQuery('(max-width:424px)', { noSsr })
  const mobile = useMediaQuery('(max-width:600px)', { noSsr })
  const mobileAllUpAndDown = useMediaQuery('(min-width:600px) and (max-width: 900px)', { noSsr })
  // const mobileAllUp = useMediaQuery('min-width:800px)', { noSsr })
  const mobileAllUp = useMediaQuery('(min-width:768px)', { noSsr })
  const mobileAll = useMediaQuery('(max-width:768px)', { noSsr })
  const allMobileTab = useMediaQuery('(max-width:900px)', { noSsr })
  const allMobileTabUp = useMediaQuery('(min-width:900px)', { noSsr })
  const tabLandscape = useMediaQuery('(max-width:1199px)', { noSsr })
  const mobileBig = useMediaQuery('(min-width:600px) and (max-width: 767px)', { noSsr })
  const tabletCommon = useMediaQuery('(min-width:767px) and (max-width: 900px)', { noSsr })
  const desktopMini = useMediaQuery('(min-width:900px) and (max-width: 1199px)', { noSsr })
  const desktopUp = useMediaQuery('(min-width:1200px)', { noSsr })
  const laptop = useMediaQuery('(min-width:1400px)', { noSsr })
  const highResolution = useMediaQuery('(min-width:1921px)', { noSsr })
  const smallLaptop = useMediaQuery('(min-width:1200px) and (max-width: 1335px)', { noSsr })

  return {
    mobileAllUpAndDown,
    mobileAllUp,
    xs,
    mobile,
    mobileAll,
    allMobileTab,
    mobileBig,
    tabletCommon,
    desktopMini,
    allMobileTabUp,
    desktopUp,
    laptop,
    highResolution,
    smallLaptop,
    tabLandscape
  }
}

export default useResponsive
